import { Button, Stack } from '@mui/material';
import { ColDef } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAlerts } from '../../../contexts/Alert';
import { AddOutlined } from '@mui/icons-material';
import { useGroups } from '../../../hooks/useGroups';

const colDefault: Partial<ColDef> = {
  resizable: true,
  sortable: true,
};

const columns: ColDef[] = [
  {
    field: 'name',
    headerName: 'Nombre',
    resizable: true,
  },
  {
    field: 'principal',
    headerName: 'Principal',
  },
  {
    field: 'member-count',
    headerName: 'Miembros',
  },
];

export const Groups = () => {
  const { list: getGroups } = useGroups();
  const { show: showAlert } = useAlerts();
  const [groups, setGroups] = useState<any[]>([]);
  const gridRef = useRef<AgGridReact>();
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoading) {
      gridRef.current?.api?.showLoadingOverlay();
    } else {
      gridRef.current?.columnApi?.applyColumnState({ state: [{ colId: 'createdAt', sort: 'desc' }] });
      gridRef.current?.api?.hideOverlay();
    }
  }, [isLoading, gridRef.current]);

  useEffect(() => {
    Promise.all([getGroups()])
      .then(([groups]) => {
        setGroups(groups);
      })
      .catch((e) => {
        console.error(e);
        showAlert({ severity: 'error', content: 'Hubo un error al listar los grupos' });
      })
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <Stack spacing={2} sx={{ height: '100%' }}>
      <Stack direction='row' justifyContent='flex-end' alignItems='center'>
        <Button onClick={() => navigate('new')} startIcon={<AddOutlined />} color='primary'>
          Nuevo Grupo
        </Button>
      </Stack>
      <article id='myGrid' className='ag-theme-alpine' style={{ height: '100%' }}>
        <AgGridReact
          ref={gridRef as any}
          rowData={groups}
          columnDefs={columns.map((d) => ({ ...colDefault, ...d }))}
          onRowDoubleClicked={(r) => {
            navigate(r.data._id);
          }}
        />
      </article>
    </Stack>
  );
};
