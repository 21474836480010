import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { Drawer, ListItemIcon, ListItemText, Menu, MenuItem, MenuList, Paper, Stack, Typography } from '@mui/material';
import {
  AccountBox,
  AccountCircle,
  AddOutlined,
  Face,
  Home,
  People,
  PointOfSale,
  ReceiptLongOutlined,
} from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';

const PAGE_NAME_BY_LOCATION: Record<string, string> = {
  '/admin/loans/new': 'Nuevo Crédito',
  '/admin/transactions': 'Historial de transacciones',
  '/admin/transactions/new': 'Nueva transacción',
  '/admin/groups': 'Grupos',
  '/admin/participants': 'Participantes',
  '/admin/loans': 'Créditos otorgados',
  '/admin/users/new': 'Nuevo Usuario',
  '/admin/users': 'Usuarios',
  '/admin': 'Admin',
};

export const Header = () => {
  const location = useLocation();
  const [pageName, setPageName] = useState('');
  const [drawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
    setDrawerOpen(false);
    const [, newPageName] =
      Object.entries(PAGE_NAME_BY_LOCATION).find(([p]) => new RegExp(p).test(location.pathname)) ?? [];
    setPageName(newPageName ?? 'Admin');
  }, [location]);

  const toggleDrawer = (open: boolean) => (event: any) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setDrawerOpen(open);
  };

  return (
    <>
      <AppBar position='sticky'>
        <Toolbar>
          <IconButton
            size='large'
            edge='start'
            color='inherit'
            aria-label='menu'
            sx={{ mr: 2 }}
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant='h6' component='div' sx={{ flexGrow: 1 }}>
            {pageName}
          </Typography>
          <AccountMenu />
        </Toolbar>
      </AppBar>
      <Drawer anchor='left' open={drawerOpen} onClose={toggleDrawer(false)} BackdropProps={{}}>
        <PageSidebar />
      </Drawer>
    </>
  );
};

export const AccountMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { logout, email } = useAuth();

  const handleMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Stack direction='row' alignItems='center'>
        <Typography variant='body1'>{email}</Typography>
        <IconButton
          size='large'
          aria-label='account of current user'
          aria-controls='menu-appbar'
          aria-haspopup='true'
          onClick={handleMenu}
          color='inherit'
        >
          <AccountCircle />
        </IconButton>
      </Stack>
      <Menu
        id='menu-appbar'
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose}>Profile</MenuItem>
        <MenuItem onClick={logout}>Cerrar Sesión</MenuItem>
      </Menu>
    </div>
  );
};

export const PageSidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const goTo = (page: string) => () => navigate(page);

  return (
    <Paper sx={{ width: 320, maxWidth: '100%' }} elevation={0}>
      <MenuList>
        <MenuItem onClick={goTo('/admin')} color={location.pathname === '/admin' ? 'primary' : 'default'}>
          <ListItemIcon>
            <Home fontSize='small' />
          </ListItemIcon>
          <ListItemText>Admin</ListItemText>
        </MenuItem>
        <MenuList>
          <MenuItem
            onClick={goTo('/admin/groups')}
            color={location.pathname === '/admin/groups' ? 'primary' : 'default'}
          >
            <ListItemIcon>
              <People fontSize='small' />
            </ListItemIcon>
            <ListItemText>Grupos</ListItemText>
          </MenuItem>
          <MenuItem
            onClick={goTo('/admin/participants')}
            color={location.pathname === '/admin/participants' ? 'primary' : 'default'}
          >
            <ListItemIcon>
              <Face fontSize='small' />
            </ListItemIcon>
            <ListItemText>Participantes</ListItemText>
          </MenuItem>
          <MenuItem onClick={goTo('/admin/loans')} color={location.pathname === '/admin/loans' ? 'primary' : 'default'}>
            <ListItemIcon>
              <ReceiptLongOutlined fontSize='small' />
            </ListItemIcon>
            <ListItemText>Créditos</ListItemText>
          </MenuItem>
          <MenuItem
            onClick={goTo('/admin/loans/new')}
            color={location.pathname === '/admin/loans/new' ? 'primary' : 'default'}
          >
            <ListItemIcon>
              <AddOutlined fontSize='small' />
            </ListItemIcon>
            <ListItemText>Nuevo Crédito</ListItemText>
          </MenuItem>
          <MenuItem
            onClick={goTo('/admin/transactions')}
            color={location.pathname === '/admin/transactions' ? 'primary' : 'default'}
          >
            <ListItemIcon>
              <PointOfSale fontSize='small' />
            </ListItemIcon>
            <ListItemText>Transacciones</ListItemText>
          </MenuItem>
          <MenuItem onClick={goTo('/admin/users')} color={location.pathname === '/admin/users' ? 'primary' : 'default'}>
            <ListItemIcon>
              <AccountBox fontSize='small' />
            </ListItemIcon>
            <ListItemText>Usuarios</ListItemText>
          </MenuItem>
        </MenuList>
      </MenuList>
    </Paper>
  );
};
