import { AddOutlined, Close, DeleteOutline } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  FormControl,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Modal,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useAlerts } from '../../../contexts/Alert';
import { useGroups } from '../../../hooks/useGroups';
import { useParticipants } from '../../../hooks/useParticipants';
import { User } from '../../../models/User/User';

type ParticipantsProps = {
  groupId: string;
  participants: User[];
  refresh: () => void;
};

export const Participants = ({ groupId, participants, refresh }: ParticipantsProps) => {
  const [openModal, setOpenModal] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { show } = useAlerts();
  const [allParticipants, setAllParticipants] = useState<User[]>([]);
  const [newMember, setNewMember] = useState<string>('');
  const { list } = useParticipants();
  const { newMember: addMember, deleteMember } = useGroups();

  useEffect(() => {
    list()
      .then((allParticipants) => {
        console.log(allParticipants);
        setAllParticipants(allParticipants);
      })
      .catch((e) => {
        show({ severity: 'error', content: 'Hubo un error mientras se buscaban los miembros' });
      });
  }, []);

  const saveMember = () => {
    if (newMember) {
      setIsLoading(true);
      addMember(newMember, groupId)
        .then(() => {
          refresh();
        })
        .then(() => setOpenModal(false))
        .catch((err: any) => {
          show({ severity: 'error', content: err.error });
        })
        .finally(() => setIsLoading(false));
    }
  };

  const removeMember = () => {
    if (confirmDelete) {
      setIsLoading(true);
      deleteMember(confirmDelete, groupId)
        .then(() => {
          refresh();
        })
        .then(() => setConfirmDelete(''))
        .catch((err: any) => {
          show({ severity: 'error', content: err.error });
        })
        .finally(() => setIsLoading(false));
    }
  };

  return (
    <>
      <Typography variant='h6'>Miembros</Typography>
      <List dense={false}>
        {participants?.map((participant, idx) => {
          return (
            <ListItem
              secondaryAction={
                <IconButton edge='end' aria-label='delete' onClick={() => setConfirmDelete(participant._id)}>
                  <DeleteOutline />
                </IconButton>
              }
            >
              <ListItemAvatar>
                <Avatar>{idx + 1}</Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={`${participant.lastname}, ${participant.name}`}
                secondary={
                  !!participant.idType && !!participant.idNumber && `${participant.idType}, ${participant.idNumber}`
                }
              />
            </ListItem>
          );
        })}
      </List>
      <Button onClick={() => setOpenModal(true)} startIcon={<AddOutlined />} color='primary'>
        Nuevo Miembro
      </Button>
      <Modal open={openModal} onClose={() => !isLoading && setOpenModal(false)}>
        <Card style={{ position: 'absolute', transform: 'translate(-50%, -50%)', width: 400, top: '50%', left: '50%' }}>
          <CardHeader
            title={
              <Stack direction='row' alignItems='center' justifyContent='space-between'>
                <Typography variant='h6' sx={{ fontSize: '14px' }}>
                  Nuevo miembro
                </Typography>

                <IconButton onClick={() => setOpenModal(false)}>
                  <Close color='primary' />
                </IconButton>
              </Stack>
            }
          />
          <CardContent>
            {allParticipants.filter((ap) => !participants?.find((p) => p._id === ap._id)).length ? (
              <FormControl fullWidth disabled={isLoading}>
                <InputLabel>Miembros</InputLabel>
                <Select variant='standard' onChange={(evt) => setNewMember(evt.target.value as string)}>
                  {allParticipants
                    .filter((ap) => !participants?.find((p) => p._id === ap._id))
                    .map((opt: User) => {
                      return <MenuItem value={opt._id}>{`${opt.lastname}, ${opt.name} (${opt.state})`}</MenuItem>;
                    })}
                </Select>
              </FormControl>
            ) : (
              <Typography>No hay participantes sin grupo.</Typography>
            )}
          </CardContent>
          <CardActions sx={{ justifyContent: 'flex-end' }}>
            <Button onClick={() => setOpenModal(false)} disabled={isLoading}>
              Cancelar
            </Button>
            <Button onClick={() => saveMember()} variant='contained' color='primary' disabled={isLoading}>
              Guardar
            </Button>
          </CardActions>
        </Card>
      </Modal>
      <Modal open={!!confirmDelete} onClose={() => !isLoading && setConfirmDelete('')}>
        <Card style={{ position: 'absolute', transform: 'translate(-50%, -50%)', width: 400, top: '50%', left: '50%' }}>
          <CardHeader
            title={
              <Stack direction='row' alignItems='center' justifyContent='space-between'>
                <Typography variant='h6' sx={{ fontSize: '14px' }}>
                  Borrar miembro
                </Typography>
                <IconButton onClick={() => setConfirmDelete('')}>
                  <Close color='primary' />
                </IconButton>
              </Stack>
            }
          />
          <CardContent>Está seguro qeu desea borrar a este participante del grupo?</CardContent>
          <CardActions sx={{ justifyContent: 'flex-end' }}>
            <Button onClick={() => setConfirmDelete('')} disabled={isLoading}>
              Cancelar
            </Button>
            <Button onClick={() => removeMember()} variant='contained' color='primary' disabled={isLoading}>
              Continuar
            </Button>
          </CardActions>
        </Card>
      </Modal>
    </>
  );
};
