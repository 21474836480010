import { Button, Divider, Stack, Typography } from '@mui/material';
import { ColDef, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAlerts } from '../../../contexts/Alert';
import { format } from 'currency-formatter';
import dayjs from 'dayjs';
import { AddOutlined } from '@mui/icons-material';
import { Transaction } from '../../../models/Transactions/Transaction';
import { useTransactions } from '../../../hooks/useTransactions/useTransactions';

const colDefault: Partial<ColDef> = {
  resizable: true,
  sortable: true,
};

const columns: ColDef[] = [
  {
    field: 'createdAt',
    headerName: 'Fecha',
    resizable: true,
    valueFormatter: (p: ValueFormatterParams) => dayjs(p.value).format('DD/MM/YY hh:mm:ss'),
    valueGetter: (p: ValueGetterParams) => p.data.createdAt,
  },
  {
    field: 'credits',
    headerName: 'Créditos',
    valueFormatter: (p: ValueFormatterParams) => format(p.value, { code: 'ARS' }),
    valueGetter: (p: ValueGetterParams) => {
      return p.data.isCredit() ? p.data.amount : undefined;
    },
  },
  {
    field: 'debits',
    headerName: 'Débitos',
    valueFormatter: (p: ValueFormatterParams) => format(p.value, { code: 'ARS' }),
    valueGetter: (p: ValueGetterParams) => {
      return !p.data.isCredit() ? -p.data.amount : undefined;
    },
  },
  {
    field: 'details',
    headerName: 'Detalles',
    valueGetter: (p: ValueGetterParams) => {
      return p.data.details?.es;
    },
  },
];

export const Transactions = () => {
  const { list: getTransactions } = useTransactions();
  const { show: showAlert } = useAlerts();
  const [transactions, setTransactions] = useState<any[]>([]);
  const gridRef = useRef<AgGridReact>();
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoading) {
      gridRef.current?.api?.showLoadingOverlay();
    } else {
      gridRef.current?.api?.hideOverlay();
    }
  }, [isLoading, gridRef.current]);

  useEffect(() => {
    Promise.all([getTransactions()])
      .then(([transactions]) => {
        setTransactions(transactions.map((t: any) => new Transaction(t)));
      })
      .catch((e) => {
        console.error(e);
        showAlert({ severity: 'error', content: 'Hubo un error al listar las transacciones' });
      })
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    if (transactions && gridRef.current) {
      gridRef.current?.columnApi?.applyColumnState({ state: [{ colId: 'createdAt', sort: 'desc' }] });
    }
  }, [gridRef.current, transactions]);

  return (
    <Stack spacing={2} sx={{ height: '100%' }}>
      <Stack direction='row' justifyContent='space-between' alignItems='center'>
        <Stack direction='row' divider={<Divider orientation='vertical' flexItem />} spacing={2}>
          <Typography variant='body2'>
            <strong>Balance: </strong>
            {format(
              transactions.reduce((t, tx: Transaction) => (t += tx.amount), 0),
              { code: 'ARS' }
            )}
          </Typography>
        </Stack>
        <Button onClick={() => navigate('new')} startIcon={<AddOutlined />} color='primary'>
          Nueva Transacción
        </Button>
      </Stack>
      <article id='myGrid' className='ag-theme-alpine' style={{ height: '100%' }}>
        <AgGridReact
          ref={gridRef as any}
          rowData={transactions}
          columnDefs={columns.map((d) => ({ ...colDefault, ...d }))}
          onRowDoubleClicked={(r) => {
            navigate(r.data.id);
          }}
        />
      </article>
    </Stack>
  );
};
