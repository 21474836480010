import { APIResponse } from '../../models/ApiResponse';
import { Requestor } from '../../models/Requestor';

export const get =
  <T>(requestor: Requestor) =>
  async (id: string) => {
    try {
      const {
        data: { data },
      } = await requestor.makeRequest.get<APIResponse<T>>(id);
      return data as T;
    } catch (e) {
      throw { success: false, error: e };
    }
  };
