import { Box } from '@mui/system';
import { Outlet } from 'react-router-dom';
import { Header } from './Header';
import { css } from '@emotion/css';
import { useAuth } from '../../hooks/useAuth';

const layoutStypes = css`
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

const mainContainerStyles = css`
  padding: 1rem;
  box-sizing: border-box;
  flex-grow: 1;
`;

export const Admin = () => {
  const user = useAuth();

  return (
    <Box className={layoutStypes}>
      <Header />
      <Box className={mainContainerStyles}>
        <Outlet />
      </Box>
    </Box>
  );
};
