import { LockOutlined, NavigateBeforeTwoTone } from '@mui/icons-material';
import { Typography, Container, Avatar, TextField, Button, Box, Stack, Link } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAlerts } from '../../contexts/Alert';
import { useAuth, useLogin } from '../../hooks/useAuth';

function Copyright() {
  return (
    <Typography variant='body2' color='textSecondary' align='center'>
      {'Copyright © '}
      <Link color='inherit' href='https://webbuilders.com.ar/'>
        WebBuilders
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const isFormValid = (form: any = {}) => {
  return Object.values(form).every((v) => !!v);
};

export function Login() {
  const [form, setForm] = useState({ username: '', password: '' });
  const auth = useAuth();
  const alert = useAlerts();
  const navigate = useNavigate();

  const handleFormSubmit = async (e: any) => {
    e.preventDefault();
    if (!form.username || !form.password) {
      alert.show({ severity: 'error', content: 'Por favor completa nombre y apellido' });
      return;
    }
    try {
      await auth.login(form);
      alert.show({ severity: 'success', content: 'Ingresaste correctamente' });
      navigate('/admin');
    } catch (e) {
      console.error(e);
      alert.show({ severity: 'error', content: 'Hubo un error mientras se intentaba realizar el ingreso' });
    }
  };

  useEffect(() => {
    if (auth.isLoggedIn) {
      console.log('entered here');
      navigate('/admin');
    }
  }, [navigate, auth.isLoggedIn]);

  return (
    <Container
      component='main'
      maxWidth='xs'
      sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
    >
      <Stack alignItems='center'>
        <Avatar>
          <LockOutlined />
        </Avatar>
        <Typography component='h1' variant='h5' sx={{ mt: 2 }}>
          Ingresar
        </Typography>
        <form style={{ width: '100%', marginTop: '1rem' }} noValidate onSubmit={handleFormSubmit}>
          <TextField
            variant='outlined'
            margin='normal'
            required
            fullWidth
            id='username'
            label='Username'
            name='username'
            autoComplete='username'
            onChange={(v) => setForm({ ...form, username: v.currentTarget.value })}
            autoFocus
          />
          <TextField
            variant='outlined'
            margin='normal'
            required
            fullWidth
            name='password'
            label='Contraseña'
            type='password'
            id='password'
            onChange={(v) => setForm({ ...form, password: v.currentTarget.value })}
            autoComplete='current-password'
          />
          <Button
            type='submit'
            fullWidth
            variant='contained'
            color='primary'
            sx={{ mt: 3 }}
            disabled={!isFormValid(form) || auth.isLoadingAuth}
          >
            Ingresar
          </Button>
          <Stack direction='row' justifyContent='space-between' sx={{ mt: 3 }}>
            <Link>Olvidaste tu contraseña?</Link>
            <Link href='/signup'>Registrarme</Link>
          </Stack>
        </form>
      </Stack>
      <Box mt={3}>
        <Copyright />
      </Box>
    </Container>
  );
}
