import { Button, Stack } from '@mui/material';
import { ColDef, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAlerts } from '../../../contexts/Alert';
import { AddOutlined } from '@mui/icons-material';
import { useParticipants } from '../../../hooks/useParticipants';
import { User } from '../../../models/User/User';

const colDefault: Partial<ColDef> = {
  resizable: true,
  sortable: true,
};

const columns: ColDef[] = [
  {
    field: 'state',
    headerName: 'Estado',
    resizable: true,
  },
  {
    field: 'name',
    headerName: 'Nombre',
    resizable: true,
  },
  {
    field: 'lastname',
    headerName: 'Apellido',
  },
  {
    field: 'idType',
    headerName: 'Tipo de Documento',
  },
  {
    field: 'idNumber',
    headerName: 'Documento',
    valueFormatter: (r: ValueFormatterParams) => r.value?.toUpperCase() ?? '-',
  },
  {
    field: 'groupName',
    headerName: 'Grupo',
    valueGetter: (r: ValueGetterParams) => r.data.team?.name ?? 'Sin Grupo',
  },
];

export const Participants = () => {
  const { list: getParticipants } = useParticipants();
  const { show: showAlert } = useAlerts();
  const [participants, setParticipants] = useState<any[]>([]);
  const gridRef = useRef<AgGridReact>();
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoading) {
      gridRef.current?.api?.showLoadingOverlay();
    } else {
      gridRef.current?.columnApi?.applyColumnState({ state: [{ colId: 'createdAt', sort: 'desc' }] });
      gridRef.current?.api?.hideOverlay();
    }
  }, [isLoading, gridRef.current]);

  useEffect(() => {
    Promise.all([getParticipants()])
      .then(([participants]) => {
        setParticipants(participants.map((p) => new User(p)));
      })
      .catch((e) => {
        showAlert({ severity: 'error', content: 'Hubo un error al listar los participantes' });
      })
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <Stack spacing={2} sx={{ height: '100%' }}>
      <Stack direction='row' justifyContent='flex-end' alignItems='center'>
        <Button onClick={() => navigate('new')} startIcon={<AddOutlined />} color='primary'>
          Nuevo Participante
        </Button>
      </Stack>
      <article id='myGrid' className='ag-theme-alpine' style={{ height: '100%' }}>
        <AgGridReact
          ref={gridRef as any}
          rowData={participants}
          columnDefs={columns.map((d) => ({ ...colDefault, ...d }))}
          onRowDoubleClicked={(r) => {
            navigate(r.data._id);
          }}
        />
      </article>
    </Stack>
  );
};
