import { Box, CircularProgress } from '@mui/material';
import { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';

export const ProtectRoute = (component: any) => {
  const user = useAuth();
  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (!user.isLoadingAuth) {
      setReady(true);
    }
  }, [user.isLoadingAuth]);

  if (user.isLoadingAuth) {
    return (
      <Box sx={{ height: '100vh', witdh: '100vw', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <CircularProgress></CircularProgress>
      </Box>
    );
  }
  return ready && !user.isLoggedIn ? <Navigate to='/login' replace /> : component;
};
