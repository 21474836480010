import { APIResponse } from '../../models/ApiResponse';
import { Group } from '../../models/Groups/Group';
import { User } from '../../models/User/User';
import { useBackend } from '../useBackend';

export const useGroups = () => {
  const { endpoints } = useBackend<Group>('teams');
  const { requestor } = useBackend('users');
  return {
    ...endpoints,
    listMembers: (teamId: string) =>
      requestor.makeRequest.get<APIResponse<User[]>>(`?team=${teamId}`).then((r) => r.data.data as User[]),
    deleteMember: (userId: string, teamId: string) =>
      requestor.makeRequest.put(`/${userId}?team=${teamId}`, { team: null }).then((r) => r.data.data),
    newMember: (userId: string, teamId: string) =>
      requestor.makeRequest.put<APIResponse<User>>(`/${userId}`, { team: teamId }).then((r) => r.data.data),
  };
};
