import { useMemo } from 'react';
import { create } from './create';
import { list } from './list';
import { get } from './get';
import { edit } from './edit';
import { Requestor, Validator } from '../../models/Requestor';

export const useBackend = <T = any>(path: string, config?: { query?: Record<string, any>; validator?: Validator }) => {
  const requestor = new Requestor(path, config);
  return useMemo(
    () => ({
      requestor,
      endpoints: {
        create: create<T>(requestor),
        edit: edit<T>(requestor),
        list: list<T>(requestor),
        get: get<T>(requestor),
      },
    }),
    []
  );
};
