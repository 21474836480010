import { useEffect, useState, createContext, useContext, useCallback, useMemo } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import { useCookies } from 'react-cookie';

const UserContext = createContext<any>({});
export const useAuth = () => useContext(UserContext);

/**
 * @example
 * <UserContextProvider
 *  onSuccess={() => function that does something on user log in}
 *  onError={() => function that does something on user error when logging in}
 * >
 *    // All the component tree that depend on user authentication to work
 * </UserContextProvider>
 * @param props
 */
export const UserContextProvider = (props: { onSuccess?: Function; onError?: Function; children: React.ReactNode }) => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<any>({});
  const [cookies, setCookies, removeCookies] = useCookies(['access_token']);
  const doLogin = useLogin();

  const login = useCallback(
    async (userCredentials: UserCredentials) => {
      setLoading(true);
      try {
        const loginResult = await doLogin(userCredentials);
        setUser((loginResult as any).user);
        props.onSuccess?.();
      } catch (e) {
        props.onError?.();
        throw e;
      } finally {
        setLoading(false);
      }
    },
    [setUser]
  );

  const logout = useCallback(() => {
    setUser({});
    removeCookies('access_token');
  }, [setUser]);

  useEffect(() => {
    if (cookies.access_token) {
      axios
        .get('users/login', { headers: { authorization: `Bearer ${cookies.access_token}` } })
        .then(({ data: response }) => {
          const {
            data: { user, token },
          } = response;
          setCookies('access_token', token.access_token, {
            expires: dayjs().add(token.expires_in).toDate(),
          });
          setUser(user);
        })
        .catch((e) => {
          /** */
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, []);

  return (
    <UserContext.Provider
      value={useMemo(
        () => ({
          isLoadingAuth: loading,
          ...user,
          logout,
          isLoggedIn: !!user.email,
          login,
        }),
        [loading, logout, login, user]
      )}
    >
      {props.children}
    </UserContext.Provider>
  );
};

type LoginResponse = { user: any; token: { access_token: string; duration: string; token_type: string } };

export const useLogin = () => {
  const [, setCookies] = useCookies();
  return useCallback(
    /**
     * @example
     * const doLogin = useLogin();
     * doLogin(form).then((response) => {
     *  if(response.isError) {
     *    // do something on error
     *  } else {
     *    // do something on successs
     *  }
     * });
     */
    (userCredentials: { email: string; password: string }): Promise<LoginResponse> => {
      return axios.post('users/login', userCredentials).then(({ data: r }) => {
        const { token } = r.data;
        setCookies('access_token', token.access_token, {
          expires: dayjs().add(token.expires_in).toDate(),
        });
        return r.data;
      });
    },
    []
  );
};

export type UserProfile = {
  name: string;
  surname?: string;
  email: string;
  isAdmin?: boolean;
};

export type UserCredentials = {
  email: string;
  password: string;
};

export const useRegister = () => {
  const { login } = useAuth();
  return useCallback(
    /**
     * @example
     * const register = useRegister();
     * register({ email: profile.email, password: password }, profile).then((r) => {
     *  if(response.isError) {
     *    // do something on error
     *  } else {
     *    // do something on successs
     *  }
     * });
     */
    async (user: UserCredentials & UserProfile) => {
      await axios.post('users', user);
      return await login(user);
    },
    [login]
  );
};
