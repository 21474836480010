import { GroupOutlined, KeyboardArrowLeftOutlined } from '@mui/icons-material';
import { Button, Container, Divider, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAlerts } from '../../../contexts/Alert';
import { useGroups } from '../../../hooks/useGroups';
import { FormField, FormGroupFields, FormGroupRow } from '../../../models/Form';
import { Group } from '../../../models/Groups/Group';
import { Participants } from './Participants';

const formStructure: FormGroupFields<Group> = [
  {
    name: 'name',
    label: 'Nombre del grupo',
    InputProps: {
      startAdornment: (
        <InputAdornment position='start'>
          <GroupOutlined />
        </InputAdornment>
      ),
    },
    required: true,
    type: 'text',
  },
];

export const CreateOrUpdateGroup = () => {
  const { create, get, listMembers } = useGroups();
  const { show } = useAlerts();
  const [isLoading, setIsLoading] = useState(false);
  const params = useParams();
  const isEdit = !!params?.groupId;
  const navigate = useNavigate();
  const [form, setForm] = useState<Group>(
    new Group({
      name: '',
    })
  );

  const refreshGroup = () => {
    if (params.groupId) {
      setIsLoading(true);
      Promise.all([get(params.groupId), listMembers(params.groupId)])
        .then(([g, participants]) => {
          const group = new Group(g);
          setForm({ ...group, members: participants });
        })
        .catch((e) => console.error(e))
        .finally(() => setIsLoading(false));
    }
  };

  useEffect(() => {
    refreshGroup();
  }, []);

  const handleChange = (field: FormField<Group>) => (event: any) => {
    const value = field.valueGetter?.(event.currentTarget.value) ?? event.currentTarget.value;
    setForm((f) => new Group({ ...f, [field.name as string]: value } as any));
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const group = await create(form);
      show({ severity: 'success', content: `Grupo #${group._id} creado satisfactoriamente` });
      setTimeout(() => navigate('/admin/groups'), 3000);
    } catch (e: any) {
      show({
        severity: 'error',
        content: (
          <>
            <Typography variant='body1'>
              Hubo un error mientras se creaba | actualizaba su grupo, inténtelo de nuevo más tarde.
            </Typography>
            <Typography variant='caption'>{e.error}</Typography>
          </>
        ),
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onCancel = () => navigate(-1);

  return (
    <Container maxWidth='md'>
      <Stack alignItems={'flex-start'}>
        <Button
          onClick={() => navigate(-1)}
          size='small'
          variant='text'
          sx={{ display: 'flex', alignItems: 'center', paddingLeft: 0 }}
          color='info'
        >
          <KeyboardArrowLeftOutlined sx={{ paddingLeft: 0 }} />
          <Typography variant='button'>Atrás</Typography>
        </Button>
        <Typography variant='h4'>{isEdit ? 'Ver Grupo' : 'Nuevo Grupo'}</Typography>
      </Stack>
      <Divider sx={{ margin: '1rem 0' }} />
      <form onSubmit={onSubmit}>
        <Stack spacing={3}>
          {formStructure.map((f: FormGroupRow<Group>) => {
            if (Array.isArray(f)) {
              return (
                <Box width='100%' gap={'1rem'} display='flex' flexDirection='row'>
                  {f.map((field) => (
                    <TextField
                      {...field}
                      fullWidth
                      variant='standard'
                      disabled={isLoading || isEdit}
                      value={field.valueFormatter?.((form as any)[field.name]) ?? (form as any)[field.name]}
                      onChange={handleChange(field)}
                    />
                  ))}
                </Box>
              );
            }
            return (
              <TextField
                {...f}
                fullWidth
                disabled={isLoading || isEdit}
                variant='standard'
                value={f.valueFormatter?.((form as any)[f.name]) ?? (form as any)[f.name]}
                onChange={handleChange(f)}
              />
            );
          })}
          {isEdit && (
            <Participants
              groupId={params.groupId as string}
              participants={form?.members as any}
              refresh={refreshGroup}
            ></Participants>
          )}
          <Box display='flex' gap='1rem' justifyContent={'flex-end'}>
            <Button color='inherit' variant='outlined' onClick={onCancel} disabled={isLoading}>
              {isEdit ? 'Volver' : 'Cancelar'}
            </Button>
            {!isEdit && (
              <Button type='submit' color='primary' variant='contained' disabled={isLoading}>
                Crear Grupo
              </Button>
            )}
          </Box>
        </Stack>
      </form>
    </Container>
  );
};
