import './App.css';
import { AlertContextProvider } from './contexts/Alert';
import { Routes } from './Routes';

import 'ag-grid-community/dist/styles/ag-grid.min.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.min.css';
import { UserContextProvider } from './hooks/useAuth';
import axios from 'axios';
import { useCookies } from 'react-cookie';

axios.defaults.baseURL = process.env.REACT_APP_BACK_END_URL;

function App() {
  const [cookies] = useCookies(['access_token']);
  axios.interceptors.request.use(function (config) {
    if (!config.headers?.authorization && cookies.access_token) {
      (config.headers as any).authorization = `Bearer ${cookies.access_token}`;
    }
    return config;
  });
  return (
    <AlertContextProvider>
      <UserContextProvider>
        <Routes />
      </UserContextProvider>
    </AlertContextProvider>
  );
}

export default App;
