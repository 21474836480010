export enum TransactionType {
  DEBIT = 'DEBIT',
  CREDIT = 'CREDIT',
  INSTALLMENT_PAY = 'INSTALLMENT_PAY',
  LOAN = 'LOAN',
}

export const TransactionTypeTranslations = {
  DEBIT: 'Débito',
  CREDIT: 'Crédito',
  INSTALLMENT_PAY: 'Pago de cuota',
  LOAN: 'Préstamo',
};

export class Transaction {
  id?: string;
  amount: number;
  details?: { es?: string };
  type: TransactionType;
  createdAt: Date;
  updatedAt: Date;

  constructor({
    id,
    type,
    details,
    amount,
    createdAt,
    updatedAt,
  }: {
    type: TransactionType;
    id?: string;
    details?: { es?: string };
    amount: number;
    createdAt?: Date;
    updatedAt?: Date;
  }) {
    this.createdAt = createdAt || new Date();
    this.updatedAt = createdAt || new Date();
    this.id = id;
    this.amount = amount;
    this.details = details;
    this.type = type;
  }

  isCredit() {
    return [TransactionType.CREDIT, TransactionType.INSTALLMENT_PAY].includes(this.type);
  }
}
