import { APIResponse } from '../../models/ApiResponse';
import { Requestor } from '../../models/Requestor';

export const list =
  <T>(requestor: Requestor) =>
  async (filters: any = {}) => {
    try {
      const {
        data: { data },
      } = await requestor.makeRequest.get<APIResponse<T[]>>(`?${new URLSearchParams(filters).toString()}`);
      return data as T[];
    } catch (e) {
      throw { success: false, error: e };
    }
  };
