import { APIResponse } from '../../models/ApiResponse';
import { Requestor } from '../../models/Requestor';

export const edit =
  <T>(requestor: Requestor) =>
  async (id: string, body: any) => {
    if (!requestor.validate.edit(body)) {
      throw { success: false, error: 'Invalid data supplied' };
    }

    try {
      const {
        data: { data },
      } = await requestor.makeRequest.put<APIResponse<T>>(id, body);
      return data as T;
    } catch (e) {
      throw { success: false, error: e };
    }
  };
