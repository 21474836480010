import { BrowserRouter, Routes as ReactRoutes, Route } from 'react-router-dom';
import { Admin } from './components/admin/Admin';
import { Dashboard } from './components/admin/Dashboard';
import { Transactions } from './components/admin/transactions/Transactions';
import { CreateTransaction } from './components/admin/transactions/CreateTransaction';
import { Groups } from './components/admin/groups/Groups';
import { CreateOrUpdateGroup } from './components/admin/groups/CreateOrUpdateGroup';
import { CreateOrUpdateParticipant } from './components/admin/participants/CreateOrUpateParticipant';
import { Participants } from './components/admin/participants/Participants';
import { useAuth } from './hooks/useAuth';
import { Login } from './components/login/Login';
import { ProtectRoute } from './components/protectRoute/ProtectRoute';
import { Signup } from './components/signup/SignUp';
import { Users } from './components/admin/users/Users';
import { CreateOrUpdateUser } from './components/admin/users/CreateOrUpateUser';

export const Routes = () => {
  const user = useAuth();

  return (
    <BrowserRouter>
      <ReactRoutes>
        <Route path='login' element={<Login />} />
        <Route path='signup' element={<Signup />} />
        <Route path='/'>
          <Route path='admin' element={ProtectRoute(<Admin />)}>
            {/* <Route path='loans' element={<Loans />}>
              <Route path=':loanId' element={<CreateOrUpdateLoan />} />
              <Route path='new' element={<CreateOrUpdateLoan />} />
              <Route index element={<ListLoans />} />
            </Route> */}
            <Route path='transactions'>
              <Route path='new' element={<CreateTransaction />} />
              <Route index element={<Transactions />} />
            </Route>
            <Route path='groups'>
              <Route path='new' element={<CreateOrUpdateGroup />} />
              <Route path=':groupId' element={<CreateOrUpdateGroup />} />
              <Route index element={<Groups />} />
            </Route>
            <Route path='participants'>
              <Route path='new' element={<CreateOrUpdateParticipant />} />
              <Route path=':participantId' element={<CreateOrUpdateParticipant />} />
              <Route index element={<Participants />} />
            </Route>
            <Route path='users'>
              <Route path='new' element={<CreateOrUpdateUser />} />
              <Route path=':userId' element={<CreateOrUpdateUser />} />
              <Route index element={<Users />} />
            </Route>
            <Route index element={ProtectRoute(<Dashboard />)}></Route>
          </Route>
        </Route>
      </ReactRoutes>
    </BrowserRouter>
  );
};
