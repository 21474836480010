import { APIResponse } from '../../models/ApiResponse';
import { Requestor } from '../../models/Requestor';

export const create =
  <T>(requestor: Requestor) =>
  async (body: any) => {
    if (!requestor.validate.create(body)) {
      throw { success: false, error: 'Invalid data supplied' };
    }

    const {
      data: { data },
    } = await requestor.makeRequest.post<APIResponse<T>>('/', body);
    return data as T;
  };
