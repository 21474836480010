import { KeyboardArrowLeftOutlined, Launch } from '@mui/icons-material';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAlerts } from '../../../contexts/Alert';
import { useUsers } from '../../../hooks/useUsers';
import { FormField, FormGroupFields, FormGroupRow } from '../../../models/Form';
import { User } from '../../../models/User/User';

const formStructure: FormGroupFields<any> = [
  [
    {
      name: 'name',
      label: 'Nombre',
      required: true,
      type: 'text',
    },
    {
      name: 'lastname',
      label: 'Apellido',
      type: 'text',
    },
  ],
  {
    name: 'email',
    label: 'Email',
    required: true,
    type: 'email',
  },
  {
    name: 'password',
    label: 'Password',
    required: true,
    type: 'password',
  },
];

export const CreateOrUpdateUser = () => {
  const { get, create, edit: update } = useUsers();
  const { show } = useAlerts();
  const params = useParams();
  const isEdit = !!params?.userId;
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [form, setForm] = useState<any>({
    role: 'admin',
    name: '',
    email: '',
    password: '',
  });

  const refreshUser = () => {
    if (params.userId) {
      setIsLoading(true);
      get(params.userId)
        .then((user) => setForm(new User(user)))
        .catch((e) => console.log('error'))
        .finally(() => setIsLoading(false));
    }
  };

  useEffect(() => {
    refreshUser();
  }, []);

  const handleChange = (field: FormField<any>) => (event: any) => {
    const value =
      field.valueGetter?.(event.currentTarget?.value ?? event.target?.value) ??
      event.currentTarget?.value ??
      event.target?.value;
    setForm((f: any) => ({ ...f, [field.name as string]: value } as any));
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      if (!isEdit) {
        const user = await create(form);
        show({ severity: 'success', content: `Usuario #${user._id} creado satisfactoriamente` });
      } else {
        await update(form.id, form);
        show({ severity: 'success', content: `Usuario #${form._id} actualizado satisfactoriamente` });
      }
      setTimeout(() => navigate('/admin/users'), 3000);
    } catch (e: any) {
      console.log(e);
      show({
        severity: 'error',
        content: (
          <>
            <Typography variant='body1'>
              Hubo un error mientras se creaba | actualizaba el usuario, inténtelo de nuevo más tarde.
            </Typography>
            <Typography variant='caption'>{e.error}</Typography>
          </>
        ),
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onCancel = () => navigate(-1);

  return (
    <Container maxWidth='md'>
      <Stack alignItems={'flex-start'}>
        <Button
          onClick={() => navigate(-1)}
          size='small'
          variant='text'
          sx={{ display: 'flex', alignItems: 'center', paddingLeft: 0 }}
          color='info'
        >
          <KeyboardArrowLeftOutlined sx={{ paddingLeft: 0 }} />
          <Typography variant='button'>Atrás</Typography>
        </Button>
        <Typography variant='h4'>{isEdit ? 'Ver Usuario' : 'Nuevo Usuario'}</Typography>
      </Stack>
      <Divider sx={{ margin: '1rem 0' }} />
      {isEdit && (
        <Stack sx={{ marginBottom: '2rem' }} direction='row' gap='1rem' justifyContent='space-evenly'>
          {!isLoading && (
            <Card sx={{ flex: '1 0' }}>
              <CardHeader
                title={
                  <Stack direction='row' justifyContent='space-between'>
                    <Typography variant='h6' sx={{ fontSize: '14px' }}>
                      Pertenece al grupo
                    </Typography>
                    {form.group ? (
                      <IconButton href={`/admin/groups/${form.group?.id}`}>
                        <Launch color='primary' />
                      </IconButton>
                    ) : (
                      <></>
                    )}
                  </Stack>
                }
              />
              <CardContent>
                <Typography variant='h4' textAlign='left'>
                  {form.group?.name ?? 'Sin Grupo'}
                </Typography>
              </CardContent>
            </Card>
          )}
        </Stack>
      )}
      <form onSubmit={onSubmit}>
        <Stack spacing={3}>
          {formStructure.map((f: FormGroupRow<any>) => {
            if (Array.isArray(f)) {
              return (
                <Box width='100%' gap={'1rem'} display='flex' flexDirection='row'>
                  {f.map((field) => {
                    return field.type === 'select' ? (
                      <FormControl fullWidth>
                        <InputLabel>{field.label}</InputLabel>
                        <Select
                          {...(field as any)}
                          variant='standard'
                          value={field.valueFormatter?.(form[field.name]) ?? form[field.name]}
                          onChange={handleChange(field)}
                          disabled={isLoading}
                        >
                          {(field.options as any).map((opt: any) => {
                            return <MenuItem value={opt.value}>{opt.label}</MenuItem>;
                          })}
                        </Select>
                      </FormControl>
                    ) : (
                      <TextField
                        {...field}
                        fullWidth
                        disabled={isLoading}
                        variant='standard'
                        value={field.valueFormatter?.(form[field.name]) ?? form[field.name]}
                        onChange={handleChange(field)}
                      />
                    );
                  })}
                </Box>
              );
            }
            return f.type === 'select' ? (
              <FormControl fullWidth>
                <InputLabel>{f.label}</InputLabel>
                <Select
                  {...(f as any)}
                  variant='standard'
                  value={f.valueFormatter?.(form[f.name]) ?? form[f.name]}
                  onChange={handleChange(f)}
                  disabled={isLoading}
                >
                  {(f.options as any).map((opt: any) => {
                    return <MenuItem value={opt.value}>{opt.label}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            ) : (
              <TextField
                {...f}
                fullWidth
                disabled={isLoading}
                variant='standard'
                value={f.valueFormatter?.((form as any)[f.name]) ?? (form as any)[f.name]}
                onChange={handleChange(f)}
              />
            );
          })}
          <Box display='flex' gap='1rem' justifyContent={'flex-end'}>
            <Button color='inherit' variant='outlined' onClick={onCancel} disabled={isLoading}>
              {isEdit ? 'Volver' : 'Cancelar'}
            </Button>
            <Button type='submit' color='primary' variant='contained' disabled={isLoading}>
              {isEdit ? 'Guardar ' : 'Crear '} Usuario
            </Button>
          </Box>
        </Stack>
      </form>
    </Container>
  );
};
