import { KeyboardArrowLeftOutlined, Launch } from '@mui/icons-material';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAlerts } from '../../../contexts/Alert';
import { useParticipants } from '../../../hooks/useParticipants';
import { FormField, FormGroupFields, FormGroupRow } from '../../../models/Form';
import { User } from '../../../models/User/User';

const formStructure: FormGroupFields<User> = [
  {
    name: 'disabled',
    label: 'Estado',
    required: true,
    type: 'select',
    options: [
      { value: false, label: 'Activo' },
      { value: true, label: 'Deshabilitado' },
    ],
  },
  [
    {
      name: 'name',
      label: 'Nombre',
      required: true,
      type: 'text',
    },
    {
      name: 'lastname',
      label: 'Apellido',
      required: true,
      type: 'text',
    },
  ],
  [
    {
      name: 'idType',
      label: 'Tipo de identificación',
      required: true,
      type: 'select',
      options: [
        { value: 'ID', label: 'DNI' },
        { value: 'VISA', label: 'Cédula' },
        { value: 'PASSPORT', label: 'Pasaporte' },
      ],
    },
    {
      name: 'idNumber',
      label: 'Nro. de documento',
      required: true,
      type: 'text',
    },
  ],
  [
    {
      name: 'email',
      label: 'Email',
      required: true,
      type: 'email',
    },
    {
      name: 'password',
      label: 'Contraseña',
      required: true,
      type: 'password',
    },
  ],
];

export const CreateOrUpdateParticipant = () => {
  const { get, create, edit: update } = useParticipants();
  const { show } = useAlerts();
  const params = useParams();
  const isEdit = !!params?.participantId;
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [form, setForm] = useState<User>(
    new User({
      role: 'participant',
      idType: 'ID',
      disabled: false,
    })
  );

  const refreshParticipant = () => {
    if (params.participantId) {
      setIsLoading(true);
      get(params.participantId)
        .then((l) => {
          console.log({ participant: l });
          setForm(new User(l));
        })
        .catch((e) => console.log('error'))
        .finally(() => setIsLoading(false));
    }
  };

  useEffect(() => {
    refreshParticipant();
  }, []);

  const handleChange = (field: FormField<User>) => (event: any) => {
    const value =
      field.valueGetter?.(event.currentTarget?.value ?? event.target?.value) ??
      event.currentTarget?.value ??
      event.target?.value;
    setForm((f) => new User({ ...f, [field.name as string]: value } as any));
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      if (!isEdit) {
        delete form.team;
        const participant = await create(form);
        show({ severity: 'success', content: `Participante #${participant._id} creado satisfactoriamente` });
      } else {
        await update(form._id, { ...form, team: typeof form.team === 'string' ? form.team : form.team?._id });
        show({ severity: 'success', content: `Participante #${form._id} actualizado satisfactoriamente` });
      }
      setTimeout(() => navigate('/admin/participants'), 3000);
    } catch (e: any) {
      show({
        severity: 'error',
        content: (
          <>
            <Typography variant='body1'>
              Hubo un error mientras se creaba | actualizaba el participante, inténtelo de nuevo más tarde.
            </Typography>
            <Typography variant='caption'>{e.error}</Typography>
          </>
        ),
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onCancel = () => navigate(-1);

  return (
    <Container maxWidth='md'>
      <Stack alignItems={'flex-start'}>
        <Button
          onClick={() => navigate(-1)}
          size='small'
          variant='text'
          sx={{ display: 'flex', alignItems: 'center', paddingLeft: 0 }}
          color='info'
        >
          <KeyboardArrowLeftOutlined sx={{ paddingLeft: 0 }} />
          <Typography variant='button'>Atrás</Typography>
        </Button>
        <Typography variant='h4'>{isEdit ? 'Ver Participante' : 'Nuevo Participante'}</Typography>
      </Stack>
      <Divider sx={{ margin: '1rem 0' }} />
      {isEdit && (
        <Stack sx={{ marginBottom: '2rem' }} direction='row' gap='1rem' justifyContent='space-evenly'>
          {!isLoading && (
            <Card sx={{ flex: '1 0' }}>
              <CardHeader
                title={
                  <Stack direction='row' justifyContent='space-between'>
                    <Typography variant='h6' sx={{ fontSize: '14px' }}>
                      Pertenece al grupo
                    </Typography>
                    {form.team ? (
                      <IconButton href={`/admin/groups/${(form.team as any)?._id}`}>
                        <Launch color='primary' />
                      </IconButton>
                    ) : (
                      <></>
                    )}
                  </Stack>
                }
              />
              <CardContent>
                <Typography variant='h4' textAlign='left'>
                  {(form.team as any)?.name ?? 'Sin Grupo'}
                </Typography>
              </CardContent>
            </Card>
          )}
        </Stack>
      )}
      <form onSubmit={onSubmit}>
        <Stack spacing={3}>
          {formStructure.map((f: FormGroupRow<User>) => {
            if (Array.isArray(f)) {
              return (
                <Box width='100%' gap={'1rem'} display='flex' flexDirection='row'>
                  {f.map((field) => {
                    return field.type === 'select' ? (
                      <FormControl fullWidth>
                        <InputLabel>{field.label}</InputLabel>
                        <Select
                          {...(field as any)}
                          variant='standard'
                          value={field.valueFormatter?.(form[field.name]) ?? form[field.name]}
                          onChange={handleChange(field)}
                          disabled={isLoading}
                        >
                          {(field.options as any).map((opt: any) => {
                            return <MenuItem value={opt.value}>{opt.label}</MenuItem>;
                          })}
                        </Select>
                      </FormControl>
                    ) : (
                      <TextField
                        {...field}
                        required={field.name === 'password' && isEdit ? false : field.required}
                        fullWidth
                        disabled={isLoading}
                        variant='standard'
                        value={field.valueFormatter?.(form[field.name]) ?? form[field.name]}
                        onChange={handleChange(field)}
                      />
                    );
                  })}
                </Box>
              );
            }
            return f.type === 'select' ? (
              <FormControl fullWidth>
                <InputLabel>{f.label}</InputLabel>
                <Select
                  {...(f as any)}
                  variant='standard'
                  value={f.valueFormatter?.(form[f.name]) ?? form[f.name]}
                  onChange={handleChange(f)}
                  disabled={isLoading}
                >
                  {(f.options as any).map((opt: any) => {
                    return <MenuItem value={opt.value}>{opt.label}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            ) : (
              <TextField
                {...f}
                required={f.name === 'password' && isEdit ? false : f.required}
                fullWidth
                disabled={isLoading}
                variant='standard'
                value={f.valueFormatter?.((form as any)[f.name]) ?? (form as any)[f.name]}
                onChange={handleChange(f)}
              />
            );
          })}
          <Box display='flex' gap='1rem' justifyContent={'flex-end'}>
            <Button color='inherit' variant='outlined' onClick={onCancel} disabled={isLoading}>
              {isEdit ? 'Volver' : 'Cancelar'}
            </Button>
            <Button type='submit' color='primary' variant='contained' disabled={isLoading}>
              {isEdit ? 'Guardar ' : 'Crear '} Participante
            </Button>
          </Box>
        </Stack>
      </form>
    </Container>
  );
};
