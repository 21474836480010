import { Button, Stack } from '@mui/material';
import { ColDef, ValueFormatterParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAlerts } from '../../../contexts/Alert';
import { AddOutlined } from '@mui/icons-material';
import { useUsers } from '../../../hooks/useUsers';

const colDefault: Partial<ColDef> = {
  resizable: true,
  sortable: true,
};

const columns: ColDef[] = [
  {
    field: 'email',
    headerName: 'Email',
    resizable: true,
  },
  {
    field: 'name',
    headerName: 'Nombre',
    resizable: true,
  },
  {
    field: 'lastname',
    headerName: 'Apellido',
  },
  {
    field: 'createdAt',
    headerName: 'Fecha de registro',
    valueFormatter: (r: ValueFormatterParams) => r.value.toUpperCase(),
  },
  {
    field: 'lastLoginAt',
    headerName: 'Último login',
  },
];

export const Users = () => {
  const { list: getUsers } = useUsers();
  const { show: showAlert } = useAlerts();
  const [users, setUsers] = useState<any[]>([]);
  const gridRef = useRef<AgGridReact>();
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoading) {
      gridRef.current?.api?.showLoadingOverlay();
    } else {
      gridRef.current?.columnApi?.applyColumnState({ state: [{ colId: 'createdAt', sort: 'desc' }] });
      gridRef.current?.api?.hideOverlay();
    }
  }, [isLoading, gridRef.current]);

  useEffect(() => {
    Promise.all([getUsers()])
      .then(([users]) => {
        setUsers(users);
      })
      .catch((e) => {
        showAlert({ severity: 'error', content: 'Hubo un error al listar los usuarios' });
      })
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <Stack spacing={2} sx={{ height: '100%' }}>
      <Stack direction='row' justifyContent='flex-end' alignItems='center'>
        <Button onClick={() => navigate('new')} startIcon={<AddOutlined />} color='primary'>
          Nuevo Usuario
        </Button>
      </Stack>
      <article id='myGrid' className='ag-theme-alpine' style={{ height: '100%' }}>
        <AgGridReact
          ref={gridRef as any}
          rowData={users}
          columnDefs={columns.map((d) => ({ ...colDefault, ...d }))}
          onRowDoubleClicked={(r) => {
            navigate(r.data._id);
          }}
        />
      </article>
    </Stack>
  );
};
