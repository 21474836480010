import { GroupOutlined, KeyboardArrowLeftOutlined } from '@mui/icons-material';
import {
  Button,
  Container,
  Divider,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAlerts } from '../../../contexts/Alert';
import { Transaction, TransactionType, TransactionTypeTranslations } from '../../../models/Transactions/Transaction';
import { FormField, FormGroupFields, FormGroupRow } from '../../../models/Form';
import { useTransactions } from '../../../hooks/useTransactions/useTransactions';

const formStructure: FormGroupFields<Transaction> = [
  [
    {
      name: 'type',
      label: 'Tipo de transacción',
      InputProps: {
        startAdornment: (
          <InputAdornment position='start'>
            <GroupOutlined />
          </InputAdornment>
        ),
      },
      required: true,
      type: 'select',
      options: Object.values(TransactionType).map((tt) => ({ label: TransactionTypeTranslations[tt], value: tt })),
    },
    {
      name: 'amount',
      label: 'Monto',
      InputProps: { startAdornment: <InputAdornment position='start'>$</InputAdornment> },
      required: true,
      type: 'number',
    },
  ],
  {
    name: 'details',
    label: 'Detalles',
    type: 'text',
  },
];

export const CreateTransaction = () => {
  const { create } = useTransactions();
  const { show } = useAlerts();
  const [isLoading, setIsLoading] = useState(false);
  const params = useParams();
  const isEdit = !!params?.loanId;
  const navigate = useNavigate();
  const [form, setForm] = useState<Transaction>(new Transaction({ type: TransactionType.DEBIT, amount: 0 }));

  const handleChange = (field: FormField<Transaction>) => (event: any) => {
    setForm(
      (f) => new Transaction({ ...f, [field.name as string]: event.currentTarget?.value ?? event.target?.value } as any)
    );
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const transaction = await create({ ...form, ...(form.details ? { details: { es: form.details } } : {}) });
      show({ severity: 'success', content: `Transacción #${transaction._id} creada satisfactoriamente` });
      setTimeout(() => navigate('/admin/transactions'), 3000);
    } catch (e: any) {
      console.log(e);
      show({
        severity: 'error',
        content: (
          <>
            <Typography variant='body1'>
              Hubo un error mientras se creaba su transacción, inténtelo de nuevo más tarde.
            </Typography>
            <Typography variant='caption'>{e.error}</Typography>
          </>
        ),
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onCancel = () => navigate(-1);

  return (
    <Container maxWidth='md'>
      <Stack alignItems={'flex-start'}>
        <Button
          onClick={() => navigate(-1)}
          size='small'
          variant='text'
          sx={{ display: 'flex', alignItems: 'center', paddingLeft: 0 }}
          color='info'
        >
          <KeyboardArrowLeftOutlined sx={{ paddingLeft: 0 }} />
          <Typography variant='button'>Atrás</Typography>
        </Button>
        <Typography variant='h4'>{isEdit ? 'Ver Préstamo' : 'Nueva Transacción'}</Typography>
      </Stack>
      <Divider sx={{ margin: '1rem 0' }} />
      <form onSubmit={onSubmit}>
        <Stack spacing={3}>
          {formStructure.map((f: FormGroupRow<Transaction>) => {
            if (Array.isArray(f)) {
              return (
                <Box width='100%' gap={'1rem'} display='flex' flexDirection='row'>
                  {f.map((field) => {
                    return field.type === 'select' ? (
                      <FormControl fullWidth>
                        <InputLabel id='demo-simple-select-label'>{field.label}</InputLabel>
                        <Select
                          {...(field as any)}
                          value={field.valueFormatter?.(form[field.name]) ?? form[field.name]}
                          onChange={handleChange(field)}
                        >
                          {(field.options as any).map((opt: any) => {
                            return <MenuItem value={opt.value}>{opt.label}</MenuItem>;
                          })}
                        </Select>
                      </FormControl>
                    ) : (
                      <TextField
                        {...field}
                        fullWidth
                        variant='standard'
                        disabled={isLoading || isEdit}
                        value={field.valueFormatter?.(form[field.name]) ?? form[field.name]}
                        onChange={handleChange(field)}
                      />
                    );
                  })}
                </Box>
              );
            }
            return (
              <TextField
                {...f}
                fullWidth
                disabled={isLoading || isEdit}
                variant='standard'
                value={f.valueFormatter?.(form[f.name]) ?? form[f.name]}
                onChange={handleChange(f)}
              />
            );
          })}
          <Box display='flex' gap='1rem' justifyContent={'flex-end'}>
            <Button color='inherit' variant='outlined' onClick={onCancel} disabled={isLoading}>
              {isEdit ? 'Volver' : 'Cancelar'}
            </Button>
            {!isEdit && (
              <Button type='submit' color='primary' variant='contained' disabled={isLoading}>
                Crear Transacción
              </Button>
            )}
          </Box>
        </Stack>
      </form>
    </Container>
  );
};
