export class User {
  public _id: string;
  public name: string;
  public lastname: string;
  public idType: string;
  public idNumber: string;
  public disabled: boolean;
  public team?: { _id: string; name: string } | string;
  public password: string;
  public email: string;
  public role: string;

  constructor({ _id, name, lastname, idType, idNumber, disabled, team, email, password, role }: any) {
    this._id = _id;
    this.name = name;
    this.lastname = lastname;
    this.idType = idType;
    this.idNumber = idNumber;
    this.disabled = disabled;
    this.team = team;
    this.password = password;
    this.email = email;
    this.role = role;
  }

  get state() {
    return this.disabled ? 'Deshabilitado' : 'Activo';
  }
}
