import { User } from '../User/User';

export class Group {
  public _id?: string;
  public name: string;
  public members?: User[];
  constructor({ id, name, members }: any) {
    this._id = id;
    this.name = name;
    this.members = members;
  }
}
